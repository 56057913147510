import { useEffect, useState } from "react";
import { useCart } from "@ludens-reklame/rubics-v3-react/cart/hooks";
import { Profile } from "@ludens-reklame/rubics-v3-sdk/profile/types";
import { useProfile } from "@ludens-reklame/rubics-v3-react/profile/hooks";
import { createOrder } from "@ludens-reklame/rubics-v3-sdk/order";
import { transformImageUrl } from "@ludens-reklame/rubics-v3-sdk/file/utils";
import { useStore } from "@ludens-reklame/rubics-v3-react/store/hooks";
import { currencyFormatter } from "../../client-utils/formatters.js";
import { Busy } from "../../internal-components/busy/busy.js";
import { arrow } from "../../icons/svg.js";
import { ProductRow } from "../cart/cart.js";

const Checkout: React.FC = () => {
  const { cart, networking, removeItems } = useCart();
  const { profile } = useProfile();
  const group = useStore().context.group;
  const [orderNotes, setOrderNotes] = useState<string>("");
  const [paymentReference, setPaymentReference] = useState<string>("");
  const [formValues, setFormValues] = useState<Partial<Profile>>({});
  const [orderSent, setOrderSent] = useState<boolean>(false);

  useEffect(() => {
    setFormValues({
      ...(profile || {}),
      billing: {
        ...(profile?.billing || {}),
        ...(group?.billing || {}),
      },
    });
  }, [profile, group]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      billing: { ...prev.billing, [id]: value },
    }));
  };

  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOrderNotes(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await createOrder({
        billing: formValues.billing,
        notes: [{ content: orderNotes }],
        meta: [{ key: "paymentReference", value: paymentReference }],
      });
      setOrderSent(true);
    } catch (error) {
      console.error(error);
      window.alert("Noe gikk galt. Prøv igjen, eller kontakt oss for hjelp.");
    }
  };

  if (orderSent) {
    return (
      <div className="checkout mw hs vs-xl">
        <div className="checkout-title">
          <h1>Takk for din bestilling</h1>
          <p className="b2">Vi har sendt kvitteringen til din e-post</p>
        </div>
        <div className="checkout-container">
          <div className="cart mobile">
            <div className="content">
              <h6 className="cart-title">Orderen din</h6>
              {cart.items.map((item) => (
                <div key={item._id} className="product">
                  <h3 className="b2">{item.product.name}</h3>
                  <div className="actions">
                    <div className="quantity">{item.quantity}</div>

                    <p
                      className={
                        item.product.compareAtPrice ? "inactive" : undefined
                      }
                    >
                      {item.product.priceText}
                    </p>
                    {item.product.compareAtPrice && (
                      <p className="compare-at-price">
                        {item.product.compareAtPriceText}
                      </p>
                    )}
                  </div>
                  <div className="total">
                    <p>Delsum:</p>
                    <p>{item.totalText}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="prices">
              <ul>
                <li>
                  <p className="h6">Delsum</p>
                  <p>{currencyFormatter.format(cart.total - cart.totalTax)}</p>
                </li>
                <li>
                  <p className="h6">MVA</p>
                  <p>{cart.totalTaxText}</p>
                </li>
                <li>
                  <p className="h6">Totalt</p>
                  <p>{cart.totalText}</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="cart desktop">
            <div className="content">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th>Produkt</th>
                    <th>Pris</th>
                    <th>Antall</th>
                    <th>Delsum</th>
                  </tr>
                </thead>
                <tbody>
                  {cart.items.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td></td>
                        <td>
                          {item.product.primaryImage && (
                            <img
                              src={transformImageUrl(
                                item.product.primaryImage,
                                {
                                  width: 144,
                                  fit: "contain",
                                }
                              )}
                              alt={item.product.name}
                            />
                          )}
                        </td>
                        <td>{item.product.name}</td>
                        <td className="unit-price">
                          <span
                            className={
                              item.product.compareAtPrice
                                ? "inactive"
                                : undefined
                            }
                          >
                            {item.product.priceText}
                          </span>
                          {item.product.compareAtPrice && (
                            <span className="compare-at-price">
                              {item.product.compareAtPriceText}
                            </span>
                          )}
                        </td>
                        <td>{item.quantity}</td>
                        <td>{item.totalText}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="prices">
                <ul>
                  <li>
                    <p className="h6">Delsum:</p>
                    <p>
                      {currencyFormatter.format(cart.total - cart.totalTax)}
                    </p>
                  </li>
                  <li>
                    <p className="h6">MVA:</p>
                    <p>{cart.totalTaxText}</p>
                  </li>
                  <li>
                    <p className="h6">Totalt:</p>
                    <p>{cart.totalText}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="checkout mw hs vs-xl">
      <h1 className="checkout-title">Kasse</h1>
      <Busy busy={networking}>
        <div className="checkout-container">
          <div className="cart">
            <div className="content">
              <h6 className="cart-title">Orderen din</h6>
              {cart.items.map((item) => (
                <ProductRow
                  key={item._id}
                  item={item}
                  removeItems={removeItems}
                  mobile={true}
                />
              ))}
            </div>
            <div className="prices">
              <ul>
                <li>
                  <p className="h6">Delsum</p>
                  <p>{currencyFormatter.format(cart.total - cart.totalTax)}</p>
                </li>
                <li>
                  <p className="h6">MVA</p>
                  <p>{cart.totalTaxText}</p>
                </li>
                <li>
                  <p className="h6">Totalt</p>
                  <p>{cart.totalText}</p>
                </li>
              </ul>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="checkout-details">
              <div className="checkout-form">
                <h6 className="subtitle">Faktureringsdetaljer</h6>
                <label className="b3">
                  Fornavn:
                  <input
                    id="firstName"
                    type="text"
                    className="b1"
                    value={
                      formValues.billing?.firstName ||
                      formValues.firstName ||
                      ""
                    }
                    onChange={handleChange}
                    required
                  />
                </label>
                <label className="b3">
                  Etternavn:
                  <input
                    id="lastName"
                    type="text"
                    className="b1"
                    value={
                      formValues.billing?.lastName || formValues.lastName || ""
                    }
                    onChange={handleChange}
                    required
                  />
                </label>
                <label className="b3">
                  Firmanavn (valgfritt):
                  <input
                    id="company"
                    type="text"
                    className="b1"
                    value={
                      group?.name ||
                      formValues.billing?.company ||
                      formValues.company
                    }
                    readOnly={!!group}
                    onChange={handleChange}
                  />
                </label>
                <label className="b3">
                  Adresse:
                  <input
                    id="addressLine1"
                    className="b1"
                    value={
                      formValues.billing?.addressLine1 ||
                      formValues.addressLine1
                    }
                    readOnly={!!group?.billing.addressLine1}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label className="b3">
                  Postnummer:
                  <input
                    id="postcode"
                    className="b1"
                    value={formValues.billing?.postcode || formValues.postcode}
                    readOnly={!!group?.billing.postcode}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label className="b3">
                  Poststed:
                  <input
                    id="city"
                    className="b1"
                    value={formValues.billing?.city || formValues.city}
                    readOnly={!!group?.billing.city}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label className="b3">
                  Telefon:
                  <input
                    id="phone"
                    className="b1"
                    value={formValues.billing?.phone || formValues.phone}
                    readOnly={!!group?.billing.phone}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label className="b3">
                  E-postadresse:
                  <input
                    type="email"
                    id="email"
                    className="b1"
                    value={formValues.billing?.email || formValues.email}
                    readOnly={!!group?.billing.email}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label className="b3">
                  Innkjøpsnr./ faktura merkes med:
                  <input
                    type="string"
                    id="paymentReference"
                    className="b1"
                    value={paymentReference}
                    onChange={(e) => setPaymentReference(e.target.value)}
                  />
                </label>
              </div>
              <div>
                <div className="extra-info">
                  <h6>Tilleggsinformasjon</h6>
                  <div className="order-notes">
                    <p className="b3">Ordrenotater: (valgfritt)</p>
                    <textarea
                      placeholder="Notater ang. ordren din. f.eks. spesielle ønsker for levering."
                      value={orderNotes}
                      onChange={handleNotesChange}
                    ></textarea>
                  </div>
                  <label>
                    <input type="checkbox" required />
                    Jeg har lest og aksepterer nettstedets vilkår og betingelser
                    *
                  </label>
                  <div>
                    <button type="submit" className="tertiary">
                      Send ordre {arrow}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Busy>
    </div>
  );
};

export default Checkout;
